<template>
<div>
    <b-card no-body class="questionnaire">
        <b-tabs card v-model="tabIndex">
            <b-tab no-body :title="section.label" v-for="section in sections" :key="section.key">
                <b-card-body>
                    <h2 class="print">{{ section.label }}</h2>
                    <div class="card-text" v-if="section.intro_html" v-html="section.intro_html"></div>
                    <p class="section-tools noprint"><a href="#" @click="toggleCollapse(false)">{{strings["expand"]}}</a> /
                    <a href="#" @click="toggleCollapse(true)">{{strings["collapse"]}}</a></p>
                </b-card-body>
                <b-list-group flush>
                    <b-list-group-item v-for="question in section.questions" :key="question.id" :id="'question-' + question.id" class="question" tabindex="0">
                        <div class="section-label" v-b-toggle="question.id">{{ question.id }}: {{ question.label }}</div>
                        <b-collapse :visible="visible[question.id] !== false" :id="question.id">
                            <div class="card-text" v-if="question.description" v-html="question.description"></div>
                            <div display="inline" style="margin-top:2 px; margin-bottom:3px;" class="card-text" v-if="question.minimum"><b>{{strings["requirement"]}}: </b> <span display="inline" v-html="question.minimum"></span></div>
                            <answer :question="question" v-on:input="updateAnswer(question.id, $event)"/>
                        </b-collapse>
                    </b-list-group-item>
                </b-list-group>
            </b-tab>
        </b-tabs>
    </b-card>
</div>
</template>

<script>
import Answer from '@/components/Answer.vue'

function data () {
    return {
        visible: {},
        tabIndex: 0,
    }
}

const methods = {
    updateAnswer (id, value) {
        this.$emit('updateQuestion', id, parseInt(value, 10))
    },

    toggleCollapse (collapsed) {
        var section = this.sections[this.tabIndex]
        for (var question of section.questions) {
            this.$set(this.visible, question.id, !collapsed)
        }
    },

    activateTab (id) {
        var idx = this.sections.findIndex(s => s.key === id)
        if (idx >= 0) {
            this.tabIndex = idx
        }
        window.scroll(0, 0)
    },

    showQuestion (id) {
        var sectionId = id.split('-')[0]
        var idx = this.sections.findIndex(s => s.key === sectionId)
        if (idx >= 0) {
            this.tabIndex = idx
        }
        this.$set(this.visible, id, true)
        this.$nextTick(function () {
            var el = document.getElementById('question-' + id)
            el.scrollIntoView()
            window.scrollBy(0, -60)
        })
    }
}

export default {
    name: 'Questions',
    props: ['sections', 'strings'],
    components: {Answer},
    methods,
    data
}
</script>

<style>
select {
    width: 100%;
}

.tab-pane:focus {
    outline: none;
}

.section-label {
    padding: 0;
    margin: 0;
    color: #5e2c16;
    font-size: 16pt;
    font-weight: bold;
    cursor: pointer;
}

.section:hover .section-label {
    color: #de6700;
}

.question {
    padding: 0 0.5em;
}

.questionnaire {
    margin-top: 1em;
}

@media (min-width: 768px) {
    .questionnaire {
        margin-bottom: 100%;
    }
}

@media print {
    .tab-content > .tab-pane {
        display: block !important;
        opacity: 1 !important;
    }
    .questionnaire {
        margin-bottom: 1em !important;
    }
    div {
        border: 0 !important;
    }
}

.print {
    display: none;
}

@media print {
    .print {
        display: block;
    }
    .noprint {
        display: none;
    }
    .card-header {
        display: none !important;
    }
}

.section-tools {
    margin: 1em 0 0 0;
}
</style>
