<template>
  <div v-if="openActions <= 0">
    <h2>{{ strings["congrat"] }}</h2>
    <p>{{ strings["noChange"] }}{{ reachedProfileDescription }}{{ TIStatus }}. </p>
  </div>

  <div v-else>

    <div v-for="section in sections" v-bind:key="section.key">
      <h3>{{ section.label }}</h3>

      <p> {{ amountChanges(section.questions, targetLevel) }} {{ strings["of"] }} {{section.questions.length}} {{ strings["improvementNeeded"] }}</p>

      <b-table-simple hover border="1px solid black" v-if="amountChanges(section.questions, targetLevel) > 0">

        <b-thead>
          <b-tr>
            <b-th>{{ strings["desState"] }}</b-th>
            <b-th>{{ strings["taskId"] }}</b-th>
            <b-th>{{ strings["taskDesc"] }}</b-th>
            <b-th>{{ strings["taskCompl"] }}</b-th>
          </b-tr>
        </b-thead>
          <b-tbody>

            <template v-for="question in section.questions">
              <b-tr v-if="question.options[question.idx][0] < question.levels[targetLevel] && question.options[question.idx][0] >= 0" variant="secondary" v-bind:key="question.id">
                <b-td colspan="6">{{ question.id + " " + question.label }}</b-td>
              </b-tr>

              <b-tr v-for="(value, name, index) in filterQuestionArray( question.levels, question.options[question.idx][0], question.levels[targetLevel] )" v-bind:key="index">
                <b-td>{{ (value - 1 ) + " &rarr; " + (value)}}</b-td>
                <b-td>{{ (pref[value - 1][1] != '' ? pref[value - 1][1] : '' ) + question.id + ( suff[value - 1][1] != '' ?  suff[value - 1][1] : question.improvement[value - 1][0] ) }}</b-td>
                <b-td>{{ (question.improvement[value - 1][1] == '') ? ( defTaskDesc[value - 1][1] != '' ? defTaskDesc[value - 1][1] : (strings["apply"] + question.options[value - 1][1] ) ) : question.improvement[value - 1][1] }}</b-td>
                <b-td><b-button variant="primary" title="Task completed" class="mr-1" @click="$emit('updateQuestion', question.id, value)" v-if=" question.options[question.idx][0] + 1 >= value  " >{{ strings["compl-btn"] }}</b-button></b-td>
              </b-tr>
            </template>
          </b-tbody>

      </b-table-simple>
      <hr />
    </div>
    <b-button v-if="enableExport" variant="primary" class="mr-1" @click="csvExport()">{{ strings["export"] }}</b-button>
</div>
</template>

<script>
import copy from 'copy-text-to-clipboard'

export default {
    props: [
        'sections',
        'targetLevel',
        'pref',
        'suff',
        'defTaskDesc',
        'openActions',
        'reachedProfileDescription',
        'reachedLevels',
        'enableExport',
        'strings',
    ],

    methods: {
        amountChanges(questions, targetLevel) {
            var count = 0
            for ( var question of questions) {
                if (question.options[question.idx][0] < question.levels[targetLevel] && question.options[question.idx][0] >= 0) {
                    count = count + 1
                }
            }
            return count
        },

        filterQuestionArray( levelsArray, currentLevel, targetLevel ) {
            let filterResult = Array.from(Array(5), (x, index) => index + 1 );
            return filterResult.filter( filterResult => (filterResult > currentLevel && filterResult <= targetLevel && currentLevel >= 0) );
        },

        csvExport () {

            var tmp = ""

            for ( var i = 0; i < this.sections.length; i++ ) {

                tmp += this.sections[i].label + "\n"
                tmp += this.amountChanges(this.sections[i].questions, this.targetLevel) + " " + this.strings["of"] + " " + this.sections[i].questions.length + " " + this.strings["improvementNeeded"] + "\n\n"
                if ( this.amountChanges(this.sections[i].questions, this.targetLevel) > 0 ) {

                    tmp += this.strings["desState"] + "\t" + this.strings["taskId"] + "\t" + this.strings["taskDesc"] + "\n"

                    for (var j = 0; j < this.sections[i].questions.length; j++ ) {

                        var returnFilterQuestionArray = this.filterQuestionArray( this.sections[i].questions[j].levels, this.sections[i].questions[j].options[this.sections[i].questions[j].idx][0], this.sections[i].questions[j].levels[this.targetLevel] )

                        if ( returnFilterQuestionArray.length > 0 ) {
                            tmp += this.sections[i].questions[j].id + ": " + this.sections[i].questions[j].label + "\t\n"

                            //tmp += this.sections[i].questions[j].options[this.sections[i].questions[j].idx][0] + "\t"
                            for (var k = 0; k < returnFilterQuestionArray.length ; k++) {
                                var before = returnFilterQuestionArray[k] - 1
                                tmp += ( before ) + " -> " + (before + 1) + "\t"

                                tmp += this.pref[before][1] + this.sections[i].questions[j].id + this.suff[before][1] + this.sections[i].questions[j].improvement[before][0] + "\t"

                                if (this.sections[i].questions[j].improvement[before][1] == '' ) {
                                    if ( this.defTaskDesc[before][1] != '' ) {
                                        tmp += this.defTaskDesc[before][1]
                                    } else {
                                        tmp += this.strings["standardImprov"] + this.sections[i].questions[j].options[before][1]
                                    }
                                } else {
                                    tmp += this.sections[i].questions[j].improvement[before][1]
                                }
                                tmp += "\n"
                            }
                            tmp += "\n"
                        }
                    }

                } else {
                    tmp += "\n"
                }
            }

            copy(tmp)
            this.$toasted.show(this.strings["succExpClipboard"]);

        },
    },
    computed: {
        TIStatus () {
            if ( this.reachedLevels[4] ) {
                return this.strings["alsoTI"]
            }
            return ''
        },
    },
}
</script>

<style>

.btn-primary:hover {
  color: #b2abd2;
  background-color: #ffaa00 !important;
  border-color: #ffaa00 !important;
}

.btn-primary {
  background-color: #ffaa00 !important;
  border-color: #ffaa00 !important;
}


</style>
