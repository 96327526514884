<template>
<div class="answer-opts" tabindex="0" @focus="onFocusQuestion" @blur="onBlurQuestion">
    <div
        v-for="opt in question.options"
        :key="opt[0]"
        class="opt"
        ref="options"
        :class="{selected: question.value == opt[0]}"
        @click="chooseAnswer(opt[0])"
        >
            <span v-if="opt[0] === -1"><span title="Not applicable">N/A</span></span>
            <span v-else>{{ opt[0] }}</span>
            <div>{{ opt[1] }}</div>
    </div>
</div>
</template>

<script>
/* Javascript % operator is "remainder", not "modulo" and therefore returns
 * negative values (e.g. -4 % 3 = -1) */
function mod(a, b) {
    return ((a % b) + b) % b;
}

/* Scrolls an answer into view after keyboard navigation */
function scrollIntoViewIfNeeded(el) {
    const topOffset = 80
    let rect = el.getBoundingClientRect()
    if (rect.top < topOffset) {
        window.scrollBy(0, rect.top - topOffset)
    } else if (rect.bottom > window.innerHeight) {
        window.scrollBy(0, rect.bottom - window.innerHeight)
    }
}

export default {
    name: 'Answer',
    props: ['question'],
    methods: {
        chooseAnswer (val) {
            this.$emit('input', val)
        },

        onFocusQuestion () {
            window.addEventListener('keydown', this.onKeyDown)
        },

        onBlurQuestion () {
            window.removeEventListener('keydown', this.onKeyDown)
        },

        onKeyDown (evt) {
            let add
            switch(evt.key) {
                case 'ArrowDown':
                    add = 1
                    break
                case 'ArrowUp':
                    add = -1
                    break
                default:
                    return
            }
            evt.stopPropagation()
            evt.preventDefault()
            let idx = mod(this.question.idx + add, this.question.options.length)
            this.$emit('input', this.question.options[idx])
            scrollIntoViewIfNeeded(this.$refs.options[idx])
        },
    }
}
</script>

<style>
.opt {
    margin: 2px;
    border: 1px solid #999;
    border-radius: 3px;
    padding: 0.5em;
    padding-left: 1em;
    box-sizing: border-box;
    background: #fff;
    border-color: #fff;
    cursor: pointer;
    width: 100%;
    font-size: 90%;
}

.opt:hover {
    background: #f9d8a5;
}

.opt.selected {
    background: #ffaa00;
    color: #000;
}

.c-alt1 .opt.selected {
    background: #eebf18;
    color: #000;
}

.c-alt2 .opt.selected {
    background: #efc615;
    color: #000;
}

.c-alt3 .opt.selected {
    background: #dad929;
    color: #000;
}

.c-alt4 .opt.selected {
    background: #dfe533;
    color: #000;
}

.c-alt5 .opt.selected {
    background: #fa4950;
    color: #000;
}

.c-alt6 .opt.selected {
    background: #fc7c1f;
    color: #000;
}

.c-alt7 .opt.selected {
    background: #d3ad5f;
    color: #000;
}

@media print {
    div.opt.selected {
        border-bottom: 2px dashed #333 !important;
        font-weight: bold;
    }
}

.opt > span:first-child {
    float: left;
    display: inline-block;
    width: 2em;
    text-align: center;
}

.opt > div {
    margin-left: 3em;
}
</style>
