<template>
    <div id="radarpie" ref="radarpie">
        <svg preserveAspectRatio="xMinYMin meet" viewBox="0 0 1000 1000">
            <defs>
                <pattern id="pattern-stripe" 
                    width="8" height="8" 
                    patternUnits="userSpaceOnUse"
                    patternTransform="rotate(45)">
                        <rect width="2" height="8" transform="translate(0,0)" fill="#aaa"></rect>
                </pattern>
            </defs>
            <g transform="translate(500, 500)" id="graph"></g>
        </svg>
        <p style="float:right;padding-right: 35%" v-if="powered"><img src="favicon.ico" style="height: 20px;width: 20px"/> powered by OpenCSIRT SIM3-check</p>
    </div>
</template>

<script>
import d3 from '@/d3min.js'


const COLOURS = {
    'yellowblue': {
        fail_primary: 'darkblue',
        fail_secondary: 'lightblue',
        success_primary: 'orange',
        success_secondary: 'yellow',
        mute_primary: 'darkgrey',
        mute_secondary: 'lightgrey',
    },
}

function data () {
    return {
        initialized: false,
        colours: COLOURS['redgreen'],
    }
}

const computed = {

    pieQuestions () {
        var allQuestions = this.sections.reduce((ary, section) => {
            return ary.concat(section.questions, [{}])
        }, [])

        var pie = d3.pie()
            .sort(null)
            .value(1)

        return pie(allQuestions)
    },

    pieSections () {
        var sections = []
        var pie = d3.pie()
            .sort(null)
            .value((d) => { return (d.questions) ? d.questions.length : 1 })

        for (var section of this.sections) {
            sections = sections.concat([section, {}])
        }

        return pie(sections)
    },

    targetLevelLine1 () {
        return this.targetLevels[this.targetLevel][0]
    },

    targetLevelLine2 () {
        return this.targetLevels[this.targetLevel][1]
    },

    maxAnswers () {
        var count = 0
        for (var section of this.sections) {
            for (var question of section.questions) {
                if (question) {
                    // answers with value -1 are 'not applicable' and not
                    // displayed as a box
                    var num = question.options
                        .map(q => (q[0] >= 0) ? 1 : 0)
                        .reduce((a, b) => a + b, 0)
                    count = Math.max(count, num)
                }
            }
        }
        return count
    },

    questionThickness () {
        return ((width / 2) - circleRadius - sectionThickness - labelThickness) / this.maxAnswers
    },
}

var width = 1000
var circleRadius = 150
var labelThickness = 50
var sectionThickness = 20

const methods = {
    isTargetReached () {
        return this.sections.reduce((reached, section) => { 
            return reached && section.reached[this.targetLevel]
        }, true)
    },

    questionClicked (evt, d) {
        this.$emit('questionClick', d.data.id)
    },

    initializeLevels () {
        for (var level = 3; level >= 0; level--) {
            for (var answer = 0; answer < this.maxAnswers; answer ++) {
                let questionArc = d3.arc()
                    .innerRadius(circleRadius + sectionThickness + (answer * this.questionThickness))
                    .outerRadius(circleRadius + sectionThickness + ((answer + 1) * this.questionThickness))

                let answers = this.svg
                    .append('g')
                    .attr('class', 'l' + level + ' l' + level + 'a' + answer)

                answers
                    .selectAll('path')
                    .data(this.pieQuestions)
                    .enter()
                    .append('path')
                    .attr('d', questionArc)
                    .attr('opacity', '1')
                    .attr('stroke-width', '2px')
                    .attr('stroke', 'white')
            }
        }
    },

    initializeGraph () {
        if  ( ! ( !this.sections || !this.sections.length || this.initialized) ) {

            this.svg = d3.select('#graph')

            // Level (inner circle)
            var circle = this.svg
                .append('g')
                .attr('class', 'level')

            circle
                .append('circle')
                .attr('cx', 0)
                .attr('cy', 0)
                .attr('r', circleRadius)

            circle
                .append('text')
                .text(() => this.targetLevelLine1)
                .attr('class', 'target-level-line1')
                .attr('fill', 'white')
                .attr('text-anchor', 'middle')
                .attr('font-size', 30)
                .attr('transform', 'translate(0, -30)')

            circle
                .append('text')
                .text(() => this.targetLevelLine2)
                .attr('class', 'target-level-line2')
                .attr('fill', 'white')
                .attr('text-anchor', 'middle')
                .attr('font-size', 30)
                .attr('transform', 'translate(0, 0)')

            circle
                .append('text')
                .text('not reached')
                .attr('class', 'target-level-reached')
                .attr('fill', 'white')
                .attr('text-anchor', 'middle')
                .attr('font-size', 30)
                .attr('transform', 'translate(0, 30)')

            // this.initializeLevels()

            // Questions (all levels)
            for (var answerIdx = 0; answerIdx < this.maxAnswers; answerIdx++) {
                let questionArc = d3.arc()
                    .innerRadius(circleRadius + sectionThickness + (answerIdx * this.questionThickness))
                    .outerRadius(circleRadius + sectionThickness + ((answerIdx + 1) * this.questionThickness))

                let questions = this.svg
                    .append('g')
                    .attr('class', 'user questions-' + answerIdx)
                    .attr('opacity', 1)

                questions
                    .selectAll('path')
                    .data(this.pieQuestions)
                    .enter()
                    .append('path')
                    .attr('d', questionArc)
                    .attr('stroke-width', '2px')
                    .attr('stroke', 'white')
                    .on('click', this.questionClicked)
            }

            let labelArc = d3.arc()
                .innerRadius(width / 2 - labelThickness)
                .outerRadius(width / 2)

            let labels = this.svg
                .append('g')
                .attr('class', 'question-labels')

            labels
                .selectAll('text')
                .data(this.pieQuestions)
                .enter()
                .append('text')
                .text(d => { return (d.data.id) ? d.data.id : '' } )
                .attr("transform", function(d) {
                    return "translate(" + labelArc.centroid(d) + ")"
                })
                .attr('fill', d => { return (d.data.id) ? 'black' : 'white' })
                .attr("text-anchor", "middle")
                .attr("font-size", 20)

            this.updateGraph()
            this.resizeRadarPie()

            this.initialized = true
        }
    },

    resizeRadarPie () {
        var height = window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight

        var width = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth

        var top = null

        if ( this.powered ) {
            if (width > 768) {
                top = this.$refs.radarpie.getBoundingClientRect().top
                this.$refs.radarpie.style.height = height - top - 15 + 'px'
            } else {
                this.$refs.radarpie.style.height = '97%'
            }
        } else {
            if (width > 768) {
                top = this.$refs.radarpie.getBoundingClientRect().top
                this.$refs.radarpie.style.height = height - top + 'px'
            } else {
                this.$refs.radarpie.style.height = '100%'
            }
        }


    },

    updateGraph () {
        var targetReached = this.isTargetReached()

        for (var answerIdx = 0; answerIdx <= this.maxAnswers; answerIdx++) {
            let questions = this.svg.select('.questions-' + answerIdx)

            questions
                .selectAll('path')
                .data(this.pieQuestions)
                .join('path')
                .attr('class', d => {
                    if (!d.data.options) {
                        return 'hide'
                    }
                    if (d.data.options.length - 1 < answerIdx) {
                        return 'hide'
                    }
                    let minVal = d.data.levels[this.targetLevel]
                    let minIdx = d.data.options.findIndex(opt => { return opt[0] >= minVal })
                    let currIdx = (d.data.options[0][0] === -1) ? answerIdx + 1 : answerIdx
                    if (d.data.idx === 0 && d.data.options[0][0] === -1) {
                        return (currIdx > d.data.options.length - 1) ? 'white' : 'mute-primary'
                    }
                    if (d.data.idx >= minIdx) {
                        if (currIdx > d.data.idx) {
                            return (currIdx > d.data.options.length - 1) ? 'white' : 'mute-secondary'
                        }
                        return (currIdx <= minIdx) ? 'success-primary' : 'success-secondary'
                    } else {
                        if (currIdx > minIdx) {
                            return (currIdx > d.data.options.length - 1) ? 'white' : 'mute-secondary'
                        }
                        return (currIdx <= d.data.idx) ? 'fail-secondary': 'fail-primary'
                    }
                })
        }

        this.svg
            .select('circle')
            .attr('class', () => {
                return targetReached ? 'success-primary' : 'fail-primary'
            })

        this.svg.select('.level')
            .select('.target-level-line1')
            .text(() => this.targetLevelLine1)

        this.svg.select('.level')
            .select('.target-level-line2')
            .text(() => this.targetLevelLine2)

        this.svg.select('.level')
            .select('.target-level-reached')
            .text(() => { return targetReached ? 'reached' : 'not reached' })
    }
}

export default {
    props: ['sections', 'targetLevel', 'targetLevels', 'powered', 'visible'],
    data,
    methods,
    computed,
    mounted () {
        this.initializeGraph()
        window.addEventListener('resize', this.resizeRadarPie)
        setTimeout(this.resizeRadarPie, 100)
    },
    destroyed () {
        window.removeEventListener('resize', this.resizeRadarPie)
    },
    watch: {
        sections: {
            handler () {
                this.initializeGraph()
                this.updateGraph()
                this.resizeRadarPie()
            },
            deep: true,
        },
        targetLevel () {
            this.resizeRadarPie()
            this.initializeGraph()
            this.updateGraph()
        },
        visible (newVal, oldVal) {
            if (newVal && !oldVal) {
                this.resizeRadarPie()
            }
        },
    }
}
</script>

<style>
#radarpie {
    padding: 20px 0;
}

svg {
    height: 100%;
    width: 100%;
}

svg path {
    cursor: pointer;
}

svg path:hover {
    opacity: 0.6;
}

svg text {
    pointer-events: none;
}

.l0, .l1, .l2, .l3 {
    visibility: visible;
}

svg .hide {
    fill: white;
}

svg .fail-primary      { fill: red; }
svg .fail-secondary    { fill: #ffaa00; }
svg .success-primary   { fill: green; }
svg .success-secondary { fill: #3fde3f; }
svg .mute-primary      { fill: url(#pattern-stripe); }
svg .mute-secondary    { fill: lightgrey; }

/* Deuteranomaly */
.c-alt1 svg .fail-primary      { fill: #cc4100; }
.c-alt1 svg .fail-secondary    { fill: #eebf18; }
.c-alt1 svg .success-primary   { fill: #195e12; }
.c-alt1 svg .success-secondary { fill: #5eb455; }
.c-alt1 svg .mute-primary      { fill: url(#pattern-stripe);  }
.c-alt1 svg .mute-secondary    { fill: #e4e4e4; }

/* Protanomaly */
.c-alt2 svg .fail-primary      { fill: #d05400; }
.c-alt2 svg .fail-secondary    { fill: #efc615; }
.c-alt2 svg .success-primary   { fill: #175510; }
.c-alt2 svg .success-secondary { fill: #5ca952; }
.c-alt2 svg .mute-primary      { fill: url(#pattern-stripe);  }
.c-alt2 svg .mute-secondary    { fill: #e4e4e4; }

/* Protanopia */
.c-alt3 svg .fail-primary      { fill: #908e00; }
.c-alt3 svg .fail-secondary    { fill: #dad929; }
.c-alt3 svg .success-primary   { fill: #37381e; }
.c-alt3 svg .success-secondary { fill: #838565; }
.c-alt3 svg .mute-primary      { fill: url(#pattern-stripe);  }
.c-alt3 svg .mute-secondary    { fill: #e4e4e4; }

/* Deuteranopia */
.c-alt4 svg .fail-primary      { fill: #9fb200; }
.c-alt4 svg .fail-secondary    { fill: #dfe533; }
.c-alt4 svg .success-primary   { fill: #302626; }
.c-alt4 svg .success-secondary { fill: #7a6e6e; }
.c-alt4 svg .mute-primary      { fill: url(#pattern-stripe);  }
.c-alt4 svg .mute-secondary    { fill: #e4e4e4; }

/* Tritanopia */
.c-alt5 svg .fail-primary      { fill: #f20000; }
.c-alt5 svg .fail-secondary    { fill: #fa4950; }
.c-alt5 svg .success-primary   { fill: #06373c; }
.c-alt5 svg .success-secondary { fill: #46838a; }
.c-alt5 svg .mute-primary      { fill: url(#pattern-stripe);  }
.c-alt5 svg .mute-secondary    { fill: #e4e4e4; }

/* Tritanomaly */
.c-alt6 svg .fail-primary      { fill: #f60000; }
.c-alt6 svg .fail-secondary    { fill: #fc7c1f; }
.c-alt6 svg .success-primary   { fill: #045d17; }
.c-alt6 svg .success-secondary { fill: #44b35c; }
.c-alt6 svg .mute-primary      { fill: url(#pattern-stripe);  }
.c-alt6 svg .mute-secondary    { fill: #e4e4e3; }

/* Achromatopsia */
.c-alt7 svg .fail-primary      { fill: #9d2929; }
.c-alt7 svg .fail-secondary    { fill: #d3ad5f; }
.c-alt7 svg .success-primary   { fill: #286328; }
.c-alt7 svg .success-secondary { fill: #71ba71; }
.c-alt7 svg .mute-primary      { fill: url(#pattern-stripe);  }
.c-alt7 svg .mute-secondary    { fill: #e4e4e3; }

</style>
