<template>
<div id="app" :class="'c-' + colourScheme">
    <b-navbar toggleable="lg" type="light" variant="light" sticky>
        <b-navbar-brand>
            <!-- <router-link :to="{ name: 'reset' }"><img :src="logoURL" id="logo"/></router-link> -->
            <!-- <img @click="$router.replace({ name: 'v1', params: { answers: '6CafM9ASw-6CafMa0Tq-HR51zswU' } })" :src="logoURL" id="logo"/> -->
            <!-- <img @click="$router.replace({ name: 'v1', params: { answers: '4zXyLE1Gw-4zXyLErHq-veBVjU0I' } })" :src="logoURL" id="logo"/> -->
            <!-- <img @click="$router.replace({ name: 'answers', params: { answers: '6ziq4sBui-69tUJdJzx-6C8twdvky' } })" :src="logoURL" id="logo"/> -->
            <a :href="links[0].href" target="_blank"><img  :src="logoURL" id="logo"/></a>
            {{ appTitle }}
        </b-navbar-brand>
        <b-navbar-toggle target="nav_collapse" />
        <b-collapse is-nav id="nav_collapse">
            <b-navbar-nav class="ml-auto">
                <b-nav-item v-for="link in topNavLinks" :key="link.href" :href="link.href" target="_blank">{{ link.title }} <img src="extLink.svg" /></b-nav-item>
                <b-nav-item v-if="!showManual" @click="showManual = !showManual">{{strings["showMan"]}}</b-nav-item>
                <b-nav-item v-if="showManual" @click="showManual = !showManual">{{strings["hideMan"]}}</b-nav-item>
                <b-nav-item-dropdown :text="strings['colSwitch']" right>
                    <b-dropdown-item @click="colourScheme = 'def'" :active="colourScheme == 'def'">{{strings["colour-NV"]}}</b-dropdown-item>
                    <b-dropdown-item @click="colourScheme = 'alt1'" :active="colourScheme == 'alt1'">{{strings["colour-Deuteranomaly"]}}</b-dropdown-item>
                    <b-dropdown-item @click="colourScheme = 'alt2'" :active="colourScheme == 'alt2'">{{strings["colour-Protanomaly"]}}</b-dropdown-item>
                    <b-dropdown-item @click="colourScheme = 'alt3'" :active="colourScheme == 'alt3'">{{strings["colour-Protanopia"]}}</b-dropdown-item>
                    <b-dropdown-item @click="colourScheme = 'alt4'" :active="colourScheme == 'alt4'">{{strings["colour-Deuteranopia"]}}</b-dropdown-item>
                    <b-dropdown-item @click="colourScheme = 'alt5'" :active="colourScheme == 'alt5'">{{strings["colour-Tritanopia"]}}</b-dropdown-item>
                    <b-dropdown-item @click="colourScheme = 'alt6'" :active="colourScheme == 'alt6'">{{strings["colour-Tritanomaly"]}}</b-dropdown-item>
                    <b-dropdown-item @click="colourScheme = 'alt7'" :active="colourScheme == 'alt7'">{{strings["colour-Achromatopsia"]}}</b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item-dropdown text="Language Selection" right v-if="languages.length > 1">
                    <b-dropdown-item v-for="language in languages" @click="changeLanguage(language.abbr)" :active="selLanguage == language.abbr" :key="language.abbr">{{language.name}}</b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>

      <b-container fluid >
        <div v-show="showManual" style="width: 100%">

          <manual
          :sections = "sections"
          :manual = "manual"
          />

        </div>

        <div v-show="!showManual">
          <b-row>
            <b-col md="6" v-if="tabIndex === 0">
                <questions ref="questions" :sections="sections" :strings="questionsStrings" v-on:updateQuestion="updateQuestion"/>
            </b-col>

            <b-col style="margin-top: 15px" :class="{sidebar: tabIndex === 0}">
              <b-row :cols="tabIndex === 0 ? 1 : 2">
                  <b-col>
                    <div v-if="!urlError" >
                      <h3 style="display:inline">{{strings["yourURLtitle"]}}</h3>
                      <b-button v-if="url" variant="primary" class="mr-1" style="float: right;" @click="copyURLToClipboard()">{{strings["copyButton"]}}</b-button>
                    </div>

                    <div v-if="url">
                      <span class="noprint" v-if="!urlError">{{strings["bookmarkAdvice"]}}<br/></span>
                      <p v-if="urlError" style="color: red">{{strings["wrongURL"]}}</p>
                      <a v-if="!urlError" :href="url" style="vertical-align: -moz-middle-with-baseline;" id="urlCopySection">{{ url }}</a>
                    </div>

                    <p v-if="!url">{{strings["noLink"]}}</p>

                  </b-col>
                  <b-col>

                      <b-row class="noprint">
                          <b-col>
                              <div>{{strings["profileChoice"]}}</div>
                          </b-col>
                      </b-row>

                      <b-row class="noprint">
                          <b-col>
                              <b-button-toolbar>
                                  <b-button-group>
                                      <b-button :variant="(targetLevel === 0) ? 'primary': ''" class="mr-1" @click="targetLevel = 0; calcOpenTasks()" v-if="features.showMembership">{{strings["statMembBase"]}}</b-button>
                                      <b-button :variant="(targetLevel === 1) ? 'primary': ''" class="mr-1" @click="targetLevel = 1; calcOpenTasks()">{{strings["statBasic"]}}</b-button>
                                      <b-button :variant="(targetLevel === 2) ? 'primary': ''" class="mr-1" @click="targetLevel = 2; calcOpenTasks()">{{strings["statInter"]}}</b-button>
                                      <b-button :variant="(targetLevel === 3) ? 'primary': ''" class="mr-1" @click="targetLevel = 3; calcOpenTasks()">{{strings["statAdvan"]}}</b-button>
                                      <b-button :variant="(targetLevel === 4) ? 'primary': ''" class="mr-1" @click="targetLevel = 4; calcOpenTasks()">{{strings["statTICert"]}}</b-button>
                                  </b-button-group>
                              </b-button-toolbar>
                          </b-col>
                      </b-row>
                  </b-col>
              </b-row>

              <b-row v-if="tabIndex > 0 && tabIndex != 3">
                  <b-col>
                      <hr/>
                      <p><b>{{strings["currStat"]}}: </b> <u>{{ reachedProfileDescription }}</u> {{strings["reached"]}} <u>{{strings["statTICert"]}}</u> {{ tiPossibleDescription }}. </p>
                      <p v-if="openActions > 0">{{strings["descOpenActions"]}} {{openActions}} {{strings["descOpenActions2"]}}. </p>
                  </b-col>
              </b-row>

              <b-row>
                  <b-col>
                      <b-tabs card v-model="tabIndex" style="margin-top: 15px">
                          <b-tab :title="strings['menuToggleSpiderChart']" v-if="features.radar">
                              <p class="noprint help">{{strings["descRadarPie"]}}</p>
                                <div id="radarChartExport">
                                  <radar-pie
                                  :sections="sections"
                                  :targetLevel="targetLevel"
                                  :targetLevels="targetLevels"
                                  :visible="tabIndex === 0"
                                  :powered="features.radarPowered"
                                  @questionClick="questionClicked"
                                  @sectionClick="sectionClicked"
                                  />
                                </div>
                          </b-tab>
                          <b-tab :title="strings['menuResultTable']" v-if="features.resultTable">
                              <result-table
                                  :sections="sections"
                                  :targetLevel="targetLevel"
                                  :targetLevels="targetLevels"
                                  :strings="resultTableStrings"
                                  :enableExport="features.resultTableExport"
                                  />
                          </b-tab>
                          <b-tab :title="titleOpenActions" v-if="openActionsEnabled">
                              <open-actions
                                  :sections="sections"
                                  :targetLevel="targetLevel"
                                  :pref="taskIdentifierPref"
                                  :suff="taskIdentifierSuff"
                                  :defTaskDesc="defaultTaskDescription"
                                  :openActions="openActions"
                                  :reachedProfileDescription="reachedProfileDescription"
                                  :reachedLevels="reachedLevels"
                                  :strings="openActionsStrings"
                                  :enableExport="features.openActionsExport"
                                  @questionClick="questionClicked"
                                  @updateQuestion="updateQuestion"
                                  />
                          </b-tab>
                          <b-tab :title="strings['menuComparison']" v-if="comparisonEnabled">
                            <comparison
                              :sections="sections"
                              :url="url"
                              :targetLevel="targetLevel"
                              :targetLevels="targetLevels"
                              :strings="comparisonStrings"
                              :statusCompRadioButton="statusCompRadioButton"
                            />
                          </b-tab>
                      </b-tabs>
                  </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
        <b-row style="background-color: #f8f9fa; margin-left: 0px; padding-top: 0.5em" variant="light">
          <div>
              <div style="display: inline"><b>{{strings["version"]}}: {{ version }} / {{ jsonMeta.version }} ({{ jsonMeta.language }}) </b></div>
              <div style="display: inline" v-for="link in footerURL" :key="link.href" ><p style="display: inline" v-if="link.href.length > 0" ><b> | <a :href="link.href" target="_blank">{{ link.title }}</a></b></p></div>
          </div>
        </b-row>
    </b-container>
</div>

</template>

<script>
import axios from 'axios'
import copy from 'copy-text-to-clipboard'

import Questions from './components/Questions.vue'
import RadarPie from './components/RadarPie.vue'
import ResultTable from './components/ResultTable.vue'
import OpenActions from './components/OpenActions-BUILD_TARGET.vue'
import Comparison from './components/Comparison-BUILD_TARGET.vue'
import Manual from './components/Manual.vue'

import { encodeAnswers, decodeAnswers } from './utils'

const methods = {

    copyURLToClipboard () {
        copy(this.url)
        this.$toasted.show(this.strings["copyURLSucc"]);
    },

    changeLanguage(newLanguage) {
        this.selLanguage = newLanguage
        Promise.all([this.loadStrings(), this.loadContent()])
            .then ( () => {
                this.calculateReachedLevels()
                var decoded = decodeAnswers(this.$route.params.answers)
                this.setAnswers(decoded.answers)
            })
    },

    loadContent() {
        return axios.get('contentJSONs/' + this.selLanguage + '.json')
            .then((response) => {

                var content = response.data
                var sections = content.questions

                this.manual = content.manual[0]
                this.taskIdentifierSuff = content.taskDescSuff
                this.taskIdentifierPref = content.taskDescPref
                this.defaultTaskDescription = content.defaultTaskDescription
                this.jsonMeta = content.JSONMetadata
                this.links = content.links
                this.footerURL = content.footerURL
                this.targetLevels = content.targetLevels

                for (var section of sections) {
                    for (var q of section.questions) {
                        if (q.options[0][0] === -1) {
                            q.value = q.options[1][0]
                            q.idx = 1
                        }
                        else {
                            q.value = q.options[0][0]
                            q.idx = 0
                        }
                        q.level = -1
                    }
                    section.level = -1
                }

                this.sections = sections
                this.calculateReachedLevels()
                this.calcOpenTasks()
            })
    },

    loadStrings() {
        return axios.get('structureJSONs/' + this.selLanguage + '.json')
            .then((response) => {
                var data = response.data

                this.strings = data.App
                this.comparisonStrings = data.Comparison
                this.resultTableStrings = data.ResultTable
                this.openActionsStrings = data.OpenActions
                this.questionsStrings = data.Questions
            })
    },

    questionClicked (id) {
        this.$refs.questions.showQuestion(id)
    },

    sectionClicked (id) {
        this.$refs.questions.activateTab(id)
    },

    updateQuestion (id, value) {
        for (var section of this.sections) {
            for (var question of section.questions) {
                if (question.id == id) {
                    question.value = value
                    for (var k = 0; k < question.options.length; k++) {
                        if (question.options[k][0] === value) {
                            question.idx = k
                            break
                        }
                    }
                }
            }
        }
        this.calculateReachedLevels()
        this.updateRoute()
        this.calcOpenTasks()
        this.urlError = false
    },

    loadFaviconURL() {
        document.querySelector('#favicon').href = this.faviconURL
    },

    setAnswers (answers) {
        var i = 0
        for (var section of this.sections) {
            for (var question of section.questions) {
                var idx = answers[i++]
                if (idx > question.options.length - 1) {
                    continue
                }
                question.idx = idx
                question.value = question.options[idx][0]
            }
        }
        this.calculateReachedLevels()
        this.calcOpenTasks()
    },

    calculateReachedLevels () {
        let reached = [true, true, true, true, true]
        for (var section of this.sections) {
            section.reached = [true, true, true, true, true]
            for (var question of section.questions) {
                question.reached = question.levels.map(l => question.value === -1 || question.value >= l)
                question.sectionReached = section.reached
                for (var l=0; l < 5; l++) {
                    section.reached[l] = section.reached[l] && question.reached[l]
                }
            }

            for ( var sectionIndex = 0; sectionIndex < reached.length; sectionIndex ++ ) {
                reached[sectionIndex] = reached[sectionIndex] && section.reached[sectionIndex]
            }
        }
        this.reachedLevels = reached
        return reached
    },

    updateRoute () {
        var answers = []
        for (var section of this.sections) {
            for (var question of section.questions) {
                answers.push(question.idx)
            }
        }
        this.$router.push({
            name: 'answers',
            params: {
                answers: encodeAnswers(2, answers),
            }
        })
        this.url = window.location.href
    },

    loadConfig () {
        return axios.get('config.json')
            .then((response) => {

                var config = response.data

                this.features = config.featureActivation
                this.languages = config.languages
                this.logoURL = config.logoURL
                this.faviconURL = config.faviconURL
                if ( this.faviconURL.match(this.REGEX_URL_VAL)) {
                    this.loadFaviconURL()
                }

                if ( ! this.features.showMembership) {
                    this.targetLevel = 1
                } else {
                    this.targetLevel = 0
                }
            })
    },

    calcOpenTasks () {
        this.openActions = 0
        for (var section of this.sections) {
            for (var question of section.questions) {
                if ( question.options[question.idx][0] >= 0 ) {
                    this.openActions += Math.max.apply(Math, [ (question.levels[this.targetLevel] - question.options[question.idx][0]) , 0 ] )
                }
            }
        }
    },

    initiateBasisState () {
        var i = 0
        for (var section of this.sections) {
            for (var question of section.questions) {
                for (var j = 0; j < question.options.length; j++ ) {
                    if (question.options[j][0] === 0) {
                        this.basisState[i] = j
                    }
                }
                i += 1
            }
        }
    }
}

function data () {
    return {
        REGEX_URL_VAL: RegExp('(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*(\\?[;&a-z\\d%_.~+=-@]*)?(\\#[-a-z\\d_@]*)?$', 'i'),
        colourScheme: 'def',
        features: [],
        defaultTaskDescription: {},
        links: [''],
        footerURL: [],
        faviconURL: "",
        languages: [],
        logoURL: "",
        manual: [],
        jsonMeta: [],
        targetLevels: [],
        openActions: 0,
        returnToComparison: ['',''],
        sections: [],
        selLanguage: 'en-gb',
        showManual: false,
        strings: {},
        resultTableStrings: {},
        openActionsStrings: {},
        questionsStrings: {},
        tabIndex: 0,
        targetLevel: 0,
        taskIdentifierSuff: '',
        taskIdentifierPref: '',
        lastValid: [],
        url: undefined,
        urlError: false,
        basisState: [],
        statusCompRadioButton: false,
        reachedLevels: [false, false, false, false, false],
    }
}

export default {
    name: 'app',
    data,
    methods,
    components: {
        Questions,
        RadarPie,
        OpenActions,
        ResultTable,
        Comparison,
        Manual
    },
    mounted () {
        this.selLanguage = "en-gb"
        Promise.all([this.loadContent(), this.loadStrings(), this.loadConfig()])
            .then(() => {
                this.initiateBasisState()
                this.calculateReachedLevels()
                this.url = this.$route.params.answers
                var decoded = decodeAnswers(this.$route.params.answers)

                if (this.url) {
                    if (decoded.version) {
                        this.urlError = false
                        this.setAnswers(decoded.answers)
                        this.lastValid = decoded.answers
                        this.url = window.location.href
                        this.statusCompRadioButton = true
                    } else {
                        this.setAnswers(this.basisState)
                        this.urlError = true
                        this.lastValid = this.basisState
                        this.statusCompRadioButton = false
                    }
                }
                this.calculateReachedLevels()
            })
    },

    watch: {
        '$route': function() {
            var decoded = decodeAnswers(this.$route.params.answers)

            if (decoded.version) {
                if ( this.urlError &&  this.lastValid != this.basisState) {
                    this.setAnswers(this.lastValid)
                }
                this.setAnswers(decoded.answers)
                this.url = window.location.href
                this.lastValid = decoded.answers
                this.urlError = false
                this.statusCompRadioButton = true
            } else {
                if ( this.url && !this.urlError) {
                    this.setAnswers(this.basisState)
                    this.urlError = true
                    this.statusCompRadioButton = false

                }
            }
        }
    },
    computed: {
        appTitle () {
            if ( this.showManual ) {
                return this.manual["title"]
            } else {
                return this.strings["titel"]
            }
        },

        reachedProfileDescription () {
            var booleanReached = this.reachedLevels

            if ( this.features.showMembership ) {
                if ( booleanReached[3] ) {
                    return 'Profile ' + this.strings['statAdvan']
                }
                if ( booleanReached[2] ) {
                    return 'Profile ' + this.strings['statInter']
                }
                if ( booleanReached[1] ) {
                    return 'Profile ' + this.strings['statBasic']
                }
                if ( booleanReached[0] ) {
                    return 'Profile ' + this.strings['statMembBase']
                }
            } else {
                if ( booleanReached[2] ) {
                    return 'Profile ' + this.strings['statAdvan']
                }
                if ( booleanReached[1] ) {
                    return 'Profile ' + this.strings['statInter']
                }
                if ( booleanReached[0] ) {
                    return 'Profile ' + this.strings['statBasic']
                }
            }
            return this.strings["noProfile"]
        },

        tiPossibleDescription () {
            if ( this.reachedLevels[4] ) {
                return this.strings["possible"]
            } else {
                return this.strings["notPossible"]
            }
        },

        titleOpenActions: function() {
            return this.strings['menuOpenActions'] + " ["  + this.openActions + "]"
        },

        version () {
            return process.env.VERSION
        },

        topNavLinks () {
            return this.links.filter(link => link.href !== '' && link.title !== '')
        },

        comparisonEnabled () {
            return (
                this.features.comparison
                && process.env.BUILD_TARGET != 'slim'
            )
        },

        openActionsEnabled () {
            return (
                this.features.openActions
                && process.env.BUILD_TARGET != 'slim'
            )
        },

    }
}
</script>

<style>
#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}

#logo {
    height: 40px;
}

@media screen and (min-width: 768px) {
    .sidebar {
        position: fixed !important;
        width: 50% !important;
        margin-left: 50%;
    }
}

@media print {
    .row {
        display: block !important;
        -ms-flex-wrap: unset !important;
        flex-wrap: unset !important;
    }

    .row > div {
        width: 100% !important;
        max-width: 100% !important;
    }

    .list-group {
        display: block !important;
    }

    .col-md-6 {
        -ms-flex: unset !important;
        -webkit-box-flex: unset !important;
        flex: unset !important;
        max-width: unset !important;
        width: unset !important;
    }

    .card {
        display: block !important;
    }

    #radarpie {
        height: 15cm !important;
    }
}

.container-fluid a {
    color: #ca3e0c;
}

.c-alt1 .container-fluid a {
    color: #ae6213;
}

.c-alt2 .container-fluid a {
    color: #b06c12;
}

.c-alt3 .container-fluid a {
    color: #8d8c18;
}

.c-alt4 .container-fluid a {
    color: #959f1a;
}

.c-alt5 .container-fluid a {
    color: #c22123;
}

.c-alt6 .container-fluid a {
    color: #c53015;
}

.c-alt7 .container-fluid a {
    color: #91513a;
}

.container-fluid .btn-primary,
.container-fluid .btn-primary:hover {
    background-color: #ffaa00 !important;
    border-color: #ffaa00 !important;
    color: #000;
}

/* Deuteranomaly */
.c-alt1 .container-fluid .btn-primary,
.c-alt1 .container-fluid .btn-primary:hover {
    background-color: #eebf18 !important;
    border-color: #EEBF18 !important;
    color: #000;
}

/* Protanomaly */
.c-alt2 .container-fluid .btn-primary,
.c-alt2 .container-fluid .btn-primary:hover {
    background-color: #efc615 !important;
    border-color: #efc615 !important;
    color: #000;
}

/* Protanopia */
.c-alt3 .container-fluid .btn-primary,
.c-alt3 .container-fluid .btn-primary:hover {
  background-color: #dad929 !important;
  border-color: #dad929 !important;
    color: #000;
}

/* Deuteranopia */
.c-alt4 .container-fluid .btn-primary,
.c-alt4 .container-fluid .btn-primary:hover {
    background-color: #dfe533 !important;
    border-color: #dfe533 !important;
    color: #000;
}

/* Tritanopia */
.c-alt5 .container-fluid .btn-primary,
.c-alt5 .container-fluid .btn-primary:hover {
    background-color: #fa4950 !important;
    border-color: #fa4950 !important;
    color: #000;
}

/* Tritanomaly */
.c-alt6 .container-fluid .btn-primary,
.c-alt6 .container-fluid .btn-primary:hover {
    background-color: #fc7c1f !important;
    border-color: #fc7c1f !important;
    color: #000;
}

/* Achromatopsia */
.c-alt7 .container-fluid .btn-primary,
.c-alt7 .container-fluid .btn-primary:hover {
    background-color: #d3ad5f !important;
    border-color: #d3ad5f !important;
    color: #000;
}

.navbar {
    border-bottom: 1px solid #ddd;
}

.help {
    margin-top: 0.5em;
    color: #666;
}

#radar {
  max-width: 100%;
  max-height: 100%;
}


</style>
