<template>
    <div>
      <div class="noprint">
        <a href="#" variant="primary" v-if="resultComparison && !( warning.length > 0 || message.length > 0)" @click.prevent="showForm = !showForm">{{strings["hideForm"]}}</a>
        <div v-if="showForm || warning.length > 0 || message.length > 0" style="padding-top:5px">
            <div>
              <div style="width:48%; display:inline-block; ">
                <p>{{strings["oldStat"]}}: </p>

                <b-form-group>
                  <span @click="selectedOld = 'URL'"><b-form-radio value="URL" v-model="selectedOld"><p>{{strings["useURL"]}}: </p></b-form-radio>
                    <b-form-input v-model="textfeld[0]" type="text" :placeholder="strings['oldStat']"></b-form-input>
                  </span>
                  <br />
                  <p><b-form-radio :disabled=" ( typeof(this.url) === 'undefined' || !statusCompRadioButton)" value="Curr" v-model="selectedOld">{{strings["useStat"]}}: <u>{{url}}</u></b-form-radio></p>
                </b-form-group>
              </div>
              <div style="width:48%; float:right; display:inline-block">
                <p>{{strings["newStat"]}}:</p>

                <b-form-group>
                  <span @click="selectedNew = 'URL'"><b-form-radio value="URL" v-model="selectedNew" ><p>{{strings["useURL"]}}: </p></b-form-radio>
                    <b-form-input v-model="textfeld[1]" type="text" :placeholder="strings['newStat']"></b-form-input>
                  </span>
                  <br />
                  <p><b-form-radio :disabled=" ( typeof(this.url) === 'undefined' || !statusCompRadioButton)" value="Curr" v-model="selectedNew" :active="true">{{strings["useStat"]}}: <u>{{url}}</u></b-form-radio></p>
                </b-form-group>
              </div>
            </div>

          <p></p>
          <b-button variant="primary" class="mr-1" @click="compareProcedure(textfeld[0], textfeld[1], selectedOld, selectedNew), showForm = !showForm || warning.length > 0 || message.length > 0">{{strings["compare"]}}</b-button>
          <p></p>

          <p v-if="warning.length != 0" class="tableImprovement" style="color: white">{{warning}}</p>
          <p v-if="message.length != 0" class="tableFurthImprovement" >{{message}}</p>
        </div>
      </div>
      <div id="resultComparison" v-if="resultComparison">
        <hr />
        <h4>Comparison</h4>
        <div id="stats">

        </div>
        <b-table-simple style="text-align:center; " bordered>
          <b-row>
            <b-col><p>{{strings["decr"]}}: </p><p>{{compareResult[2]}}</p></b-col>
            <b-col><p>{{strings["neutr"]}}: </p><p>{{compareResult[1]}}</p></b-col>
            <b-col><p>{{strings["impr"]}}: </p><p>{{compareResult[0]}}</p></b-col>

          </b-row>
        </b-table-simple>
        <hr />
        <div id="table">
          <b-table-simple>
            <b-thead>
              <b-tr class="text-center">
                <b-th class="text-left">{{strings["id"]}}</b-th>
                <b-th class="text-left">{{strings["desc"]}}</b-th>
                <b-th>{{strings["selfLevelBef"]}}</b-th>
                <b-th>-4</b-th>
                <b-th>-3</b-th>
                <b-th>-2</b-th>
                <b-th>-1</b-th>
                <b-th>{{strings["delta"]}}</b-th>
                <b-th>+1</b-th>
                <b-th>+2</b-th>
                <b-th>+3</b-th>
                <b-th>+4</b-th>
                <b-th>{{strings["selfLevelAft"]}}</b-th>
                <b-th>{{strings["maturityText"]}} <br> {{targetLevels[targetLevel][0] + " " + targetLevels[targetLevel][1]}}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-for="(section, s) in sections" :key="section.label" border="2px solid black">
              <b-tr>
                <b-td colspan="13">{{section.label}}</b-td>
              </b-tr>
              <b-tr v-for="(question, q) in section.questions" :key="question.id" class="text-center">
                <b-th class="text-left">{{question.id}}</b-th>
                <b-th class="text-left">{{question.label}}</b-th>

                <b-th>{{question.options[decodedStatus[0].answers[calcArrIDStatus(s, q)]][0]}}</b-th>

                <b-th v-bind:class="compareResult[arrLength+s][q] <= -4 ? 'diagramRed' : '' "></b-th>
                <b-th v-bind:class="compareResult[arrLength+s][q] <= -3 ? 'diagramRed' : '' "></b-th>
                <b-th v-bind:class="compareResult[arrLength+s][q] <= -2 ? 'diagramRed' : '' "></b-th>
                <b-th v-bind:class="compareResult[arrLength+s][q] <= -1 ? 'diagramRed' : '' "></b-th>

                <b-th v-bind:style="compareResult[arrLength+s][q] == 0 ? 'background-color: lightgrey':''" > {{compareResult[arrLength+s][q]}}</b-th>

                <b-th v-bind:class="compareResult[arrLength+s][q] >= 1 ? 'diagramGreen' : '' "></b-th>
                <b-th v-bind:class="compareResult[arrLength+s][q] >= 2 ? 'diagramGreen' : '' "></b-th>
                <b-th v-bind:class="compareResult[arrLength+s][q] >= 3 ? 'diagramGreen' : '' "></b-th>
                <b-th v-bind:class="compareResult[arrLength+s][q] >= 4 ? 'diagramGreen' : '' "></b-th>

                <b-th>{{question.options[decodedStatus[1].answers[calcArrIDStatus(s, q)]][0]}}</b-th>

                <b-td v-if="question.options[decodedStatus[1].answers[calcArrIDStatus(s, q)]][0] == -1" class="tableNA">{{strings["na"]}}</b-td>
                <b-td v-else-if="question.options[decodedStatus[1].answers[calcArrIDStatus(s, q)]][0] == 0 && question.levels[targetLevel] == 0" class="tableNA">{{strings["notConsidered"]}}</b-td>
                <b-td v-else-if="question.options[decodedStatus[1].answers[calcArrIDStatus(s, q)]][0] == 0" class="tableImprovement">{{strings["improvement"]}}</b-td>
                <b-td v-else-if=" question.options[decodedStatus[1].answers[calcArrIDStatus(s, q)]][0] > 0  && question.options[decodedStatus[1].answers[calcArrIDStatus(s, q)]][0] < question.levels[targetLevel]" class="tableFurthImprovement">{{strings["furthImprov"]}}</b-td>
                <b-td v-else-if="question.options[decodedStatus[1].answers[calcArrIDStatus(s, q)]][0] == question.levels[targetLevel]" class="tableCompliant">{{strings["compl"]}}</b-td>
                <b-td v-else class="tableBetter">{{strings["better"]}}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>

    <b-button v-if="resultComparison" variant="primary" class="mr-1" @click="csvExport()">{{strings["export"]}}</b-button>
  </div>
</template>

<script>
import { decodeAnswers } from '../utils'
import copy from 'copy-text-to-clipboard'

export default {
    props: ['sections', 'url', 'targetLevel', 'targetLevels', 'strings', 'statusCompRadioButton'],

    data: function() {
        return {
            arrLength: 0,
            compareResult: [0,0,0],
            decodedStatus: [],
            mappedArray: [],
            resultComparison: false,
            showForm: true,
            statuscode: [],
            textfeld: ['',''],
            TICert: [[],[]],
            warning:'',
            message: '',
            countTIA: 0,
            countTIB: 0,
            selectedOld: '',
            selectedNew: '',
            bufferTextfeld: ['', '']
        }
    },

    methods: {

        calcArrIDStatus (s,q) {
            var counter = 0
            for ( var i = 0; i < s; i++) {
                counter = counter + this.sections[i].questions.length
            }
            counter = counter + q
            return counter
        },

        csvExport () {

            var tmp = this.strings["id"] + "\t" + this.strings["desc"] + "\t" + this.strings["selfLevelBef"] + "\t"
            tmp += this.strings["delta"] + "\t"
            tmp += this.strings["selfLevelAft"] + "\t"
            tmp += this.strings["maturityText"] + " " + this.targetLevels[this.targetLevel][0] + " " + this.targetLevels[this.targetLevel][1]
            tmp += "\r\n"

            for ( var i = 0; i < this.sections.length; i++ ) {
                tmp += this.sections[i].label + "\n"
                for (var j = 0; j < this.sections[i].questions.length; j++ ) {
                    tmp += this.sections[i].questions[j].id + "\t"
                    tmp += this.sections[i].questions[j].label + "\t"

                    tmp += this.sections[i].questions[j].options[this.decodedStatus[0].answers[this.calcArrIDStatus(i, j)]][0] + "\t"
                    tmp += this.compareResult[this.arrLength+i][j] + "\t"
                    tmp += this.sections[i].questions[j].options[this.decodedStatus[1].answers[this.calcArrIDStatus(i, j)]][0] + "\t"

                    if ( this.sections[i].questions[j].options[this.decodedStatus[1].answers[this.calcArrIDStatus(i, j)]][0] == -1 ) {
                        tmp += this.strings["na"]
                    } else if ( this.sections[i].questions[j].options[this.decodedStatus[1].answers[this.calcArrIDStatus(i, j)]][0] == 0 && this.sections[i].questions[j].levels[this.targetLevel] == 0) {
                        tmp += this.strings["notConsidered"]
                    } else if ( this.sections[i].questions[j].options[this.decodedStatus[1].answers[this.calcArrIDStatus(i, j)]][0] == 0 ) {
                        tmp += this.strings["improvement"]
                    } else if ( this.sections[i].questions[j].options[this.decodedStatus[1].answers[this.calcArrIDStatus(i, j)]][0] > 0 && this.sections[i].questions[j].options[this.decodedStatus[1].answers[this.calcArrIDStatus(i, j)]][0] < this.sections[i].questions[j].levels[this.targetLevel] ) {
                        tmp += this.strings["furthImprov"]
                    } else if ( this.sections[i].questions[j].options[this.decodedStatus[1].answers[this.calcArrIDStatus(i, j)]][0] == this.sections[i].questions[j].levels[this.targetLevel] ) {
                        tmp += this.strings["compl"]
                    } else {
                        tmp += this.strings["better"]
                    }

                    tmp += "\n"
                }
                tmp += "\n"
            }

            copy(tmp)
            this.$toasted.show(this.strings["expSucc"]);

        },

        //copy( index ) {
        //  if ( typeof(this.url) == 'undefined' || this.url == null || this.url.length == 0 ) {
        //    this.warning = this.strings["errorURLCopy"]
        //  } else if ( this.url.length > 0 ) {
        //    this.textfeld[index] = this.url
        //    this.$forceUpdate()
        //  }
        //},

        testLinks() {
            this.decodedStatus[0] = decodeAnswers(this.statuscode[0])
            this.decodedStatus[1] = decodeAnswers(this.statuscode[1])

            if (this.decodedStatus[0].version && this.decodedStatus[1].version ) {
                return 0
            } else if (!this.decodedStatus[0].version ) {
                return 1
            } else {
                return 2
            }
        },

        compareLinks () {
            this.compareResult = [0, 0, 0]
            for ( var i = 0; i < this.decodedStatus[0].answers.length; i++) {
                this.mappedArray[i] = this.decodedStatus[1].answers[i] - this.decodedStatus[0].answers[i]
                if (this.mappedArray[i] == 0 ) {
                    this.compareResult[1] ++
                } else if ( this.mappedArray[i] > 0 ) {
                    this.compareResult[0] ++
                } else {
                    this.compareResult[2] ++
                }
            }
            this.arrLength = this.compareResult.length
        },

        partitionateStatus() {
            var counter = 0
            this.countTIA = 0
            this.countTIB = 0

            for ( var i = 0; i < this.sections.length; i++ ) {
                this.compareResult[this.arrLength + i] = []
                this.TICert[0][i] = []
                this.TICert[1][i] = []
                for (var j = 0; j <  this.sections[i].questions.length; j++) {
                    this.compareResult[ (this.arrLength + i) ][j] = this.mappedArray[counter]

                    this.TICert[0][i][j] = ( this.sections[i].questions[j].levels[4] <= this.sections[i].questions[j].options[this.decodedStatus[0].answers[this.calcArrIDStatus(i, j)]][0] )
                    this.TICert[1][i][j] = ( this.sections[i].questions[j].levels[4] <= this.sections[i].questions[j].options[this.decodedStatus[1].answers[this.calcArrIDStatus(i, j)]][0] )

                    if( this.TICert[0][i][j] ) {
                        this.countTIA ++
                    }
                    if( this.TICert[1][i][j] ) {
                        this.countTIB ++
                    }
                    counter ++
                }
            }
        },

        compareProcedure( prev, curr, selectedOld, selectedNew) {

            if ( selectedOld =="Curr") {
                prev = JSON.parse(JSON.stringify(this.url))
            }

            if ( selectedNew == "Curr" ) {
                curr = JSON.parse(JSON.stringify(this.url))
            }

            this.message = ''

            if ( prev == '' && curr == '' ) {
                this.resultComparison = false
                this.warning = this.strings["inputErrorA"]
            } else if ( prev.length == 0 ) {
                this.resultComparison = false
                this.warning = this.strings["inputErrorB"]
            } else if ( curr.length == 0) {
                this.resultComparison = false
                this.warning = this.strings["inputErrorC"]
            } else {
                if (prev.indexOf('/') != -1 ) {
                    this.statuscode[0] = prev.split('/').pop()
                } else {
                    this.statuscode[0] = prev
                }
                if (curr.indexOf('/') != -1 ) {
                    this.statuscode[1] = curr.split('/').pop()
                } else {
                    this.statuscode[1] = curr
                }



                if ( this.statuscode[0] == this.statuscode[1] ) {
                    this.message = this.strings["messageA"]
                }

                this.textfeld = this.statuscode

                let testCode = this.testLinks()

                if ( testCode == 0 ) {
                    this.compareLinks()
                    this.partitionateStatus()
                    this.warning = ""
                    this.resultComparison = true
                    this.selectedOld='URL'
                    this.selectedNew = 'URL'
                } else if (testCode == 1 ){
                    this.resultComparison = false
                    this.warning = this.strings["inputErrorE"]
                } else if (testCode == 2) {
                    this.resultComparison = false
                    this.warning = this.strings["inputErrorF"]
                } else {
                    this.resultComparison = false
                    this.warning = this.strings["inputErrorG"]
                }
                this.$forceUpdate()
            }
        }
    },

    mounted() {
        this.textfeld[1] = ''

        if (typeof(this.url) != 'undefined' || this.url != null ) {
            //this.textfeld[0] = this.url  // debug
            this.selectedOld = "URL"
            this.selectedNew = "Curr"
        } else {
            this.selectedOld = "URL"
            this.selectedNew = "URL"

            //this.textfeld[0] = ''
        }
    },

    watch: {
        sections: {
            handler () {
                // do stuff when sections changes
            },
            deep: true,
        },

        statusCompRadioButton () {
            if ( this.statusCompRadioButton == false ) {
                this.selectedOld = "URL"
                this.selectedNew = "URL"
            } else {
                this.selectedOld = "URL"
                this.selectedNew = "Curr"
            }

        }


    }
}

</script>

<style>

.diagramRed {
  background-color: #ff726f;
}

.diagramGreen {
  background-color: lightgreen;
}

.c-alt1 .diagramRed {
  background-color: #E2966F;
}

.c-alt1 .diagramGreen {
  background-color: #A2D59D;
}

.c-alt2 .diagramRed {
  background-color: #E5A06F;
}

.c-alt2 .diagramGreen {
  background-color: #A1CE9B;
}

.c-alt3 .diagramRed {
  background-color: #C1C06F;
}

.c-alt3 .diagramGreen {
  background-color: #B8B9A6;
}

.c-alt4 .diagramRed {
  background-color: #CAD46F;
}

.c-alt4 .diagramGreen {
  background-color: #B3ACAC;
}

.c-alt5 .diagramRed {
  background-color: #F77070;
}

.c-alt5 .diagramGreen {
  background-color: #94B8BC;
}

.c-alt6 .diagramRed {
  background-color: #FA716F;
}

.c-alt6 .diagramGreen {
  background-color: #93D4A1;
}

.c-alt7 .diagramRed {
  background-color: #C88887;
}

.c-alt7 .diagramGreen {
  background-color: #AED8AD;
}


.tableNA {
  background-color: gray;
  text-align: center;
  font-weight: bold;
}

.tableImprovement {
  background-color: red;
  text-align: center;
  font-weight: bold;
}

.tableFurthImprovement {
  background-color: #ffaa00;
  text-align: center;
  font-weight: bold;
}

.tableCompliant {
  background-color: green;
  color: white;
  text-align: center;
  font-weight: bold;
}

.tableBetter {
  background-color: #2CB22C;
  color: white;
  text-align: center;
  font-weight: bold;
}


  .c-alt1 .tableNA {
    background-color: #808080;
  }

  .c-alt1 .tableImprovement {
    background-color: #cc4100;
  }

  .c-alt1 .tableFurthImprovement {
    background-color: #eebf18;
  }

  .c-alt1 .tableCompliant {
    background-color: #195e12;
    color: white;
  }

  .c-alt1 .tableBetter {
    background-color: #5eb455;
    color: black;
  }


  .c-alt2 .tableNA {
    background-color: #808080;
  }

  .c-alt2 .tableImprovement {
    background-color: #D05400;
  }

  .c-alt2 .tableFurthImprovement {
    background-color: #EFC615;
  }

  .c-alt2 .tableCompliant {
    background-color: #175510;
    color: white;
  }

  .c-alt2 .tableBetter {
    background-color: #5CA852;
    color: black;
  }

  .c-alt3 .tableNA {
    background-color: #808080;
  }

  .c-alt3 .tableImprovement {
    background-color: #908E00;
  }

  .c-alt3 .tableFurthImprovement {
    background-color: #DAD929;
  }

  .c-alt3 .tableCompliant {
    background-color: #37381E;
    color: white;
  }

  .c-alt3 .tableBetter {
    background-color: #838565;
    color: black;
  }

  .c-alt4 .tableNA {
    background-color: #808080;
  }

  .c-alt4 .tableImprovement {
    background-color: #9FB200;
  }

  .c-alt4 .tableFurthImprovement {
    background-color: #DFE533;
  }

  .c-alt4 .tableCompliant {
    background-color: #302626;
    color: white;
  }

  .c-alt4 .tableBetter {
    background-color: #7A6E6E;
    color: black;
  }

  .c-alt5 .tableNA {
    background-color: #808080;
  }

  .c-alt5 .tableImprovement {
    background-color: #F20000;
  }

  .c-alt5 .tableFurthImprovement {
    background-color: #FA4950;
  }

  .c-alt5 .tableCompliant {
    background-color: #06373C;
    color: white;
  }

  .c-alt5 .tableBetter {
    background-color: #46838A;
    color: black;
  }

  .c-alt6 .tableNA {
    background-color: 808080;
  }

  .c-alt6 .tableImprovement {
    background-color: #F60000;
  }

  .c-alt6 .tableFurthImprovement {
    background-color: #FC7C1F;
  }

  .c-alt6 .tableCompliant {
    background-color: #045D17;
    color: white;
  }

  .c-alt6 .tableBetter {
    background-color: #44B35C;
    color: black;
  }

  .c-alt7 .tableNA {
    background-color: #80807F;
  }

  .c-alt7 .tableImprovement {
    background-color: #9D2929;
  }

  .c-alt7 .tableFurthImprovement {
    background-color: #D3AD5F;
  }

  .c-alt7 .tableCompliant {
    background-color: #286328;
    color: white;
  }

  .c-alt7 .tableBetter {
    background-color: #71BA71;
    color: black;
  }

</style>
