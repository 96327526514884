<template>
    <div>
        <b-table-simple hover style="text-align: middle" border="1px solid black">

          <b-thead>
            <b-tr>
              <b-th>{{strings["id"]}}</b-th>
              <b-th>{{strings["desc"]}}</b-th>
              <b-th style="text-align: center">{{strings["selfLevel"]}}</b-th>
              <b-th v-for="targets in targetLevels" :key="targets.join()" style="text-align: center">{{targets[0] + " " + targets[1]}}</b-th>
              <b-th style="text-align: center">{{strings["maturityText"]}} <br> {{targetLevels[targetLevel][0] + " " + targetLevels[targetLevel][1]}}</b-th>
            </b-tr>
          </b-thead>
            <b-tbody v-for="section in sections" :key="section.label">
                <b-tr>
                  <b-td colspan="9">{{section.label}}</b-td>
                </b-tr>
                <b-tr v-for="question in section.questions" :key="question.id">
                  <b-td>{{question.id}}</b-td>
                  <b-td>{{question.label}}</b-td>
                  <b-td style=" font-weight: bold; text-align: center ">{{ question.options[question.idx][0] }}</b-td>
                  <b-td v-for="(targets, level) in targetLevels" :key="targets.join()" :class=" { selected: (level == targetLevel) } " style="text-align: center">{{question.levels[level]}}</b-td>
                  <b-td v-if="question.options[question.idx][0] == -1" class="tableNA">{{strings["na"]}}</b-td>
                  <b-td v-else-if="question.options[question.idx][0] == 0 && question.levels[targetLevel] == 0" class="tableNA">{{strings["notConsidered"]}}</b-td>
                  <b-td v-else-if="question.options[question.idx][0] == 0" class="tableImprovement">{{strings["improvement"]}}</b-td>
                  <b-td v-else-if=" question.options[question.idx][0] > 0  && question.options[question.idx][0] < question.levels[targetLevel]" class="tableFurthImprovement">{{strings["furthImprov"]}}</b-td>
                  <b-td v-else-if="question.options[question.idx][0] == question.levels[targetLevel]" class="tableCompliant">{{strings["compl"]}}</b-td>
                  <b-td v-else class="tableBetter">{{strings["better"]}}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
        <b-button v-if="enableExport" variant="primary" class="mr-1" @click="csvExport()">{{strings["export"]}}</b-button>
    </div>
</template>

<script>
import copy from 'copy-text-to-clipboard'

export default {
    props: [
        'sections',
        'targetLevel',
        'targetLevels',
        'strings',
        'enableExport',
    ],
    watch: {
        sections: {
            handler () {
                // do stuff when sections changes
            },
            deep: true,
        },
        targetLevel () {
            // do stuff when targetLevel changes
        }
    },
    methods: {
        csvExport () {

            var tmp = this.strings["id"] + "\t" + this.strings["desc"] + "\t" + this.strings["selfLevel"] + "\t"

            for (var h = 0; h < this.targetLevels.length; h++ ) {
                tmp += this.targetLevels[h][0] + " " + this.targetLevels[h][1] + "\t"
            }
            tmp += this.strings["maturityText"] + " " + this.targetLevels[this.targetLevel][0] + " " + this.targetLevels[this.targetLevel][1]
            tmp += "\n"

            for ( var i = 0; i < this.sections.length; i++ ) {
                tmp += "\n" + this.sections[i].label + "\n"
                for (var j = 0; j < this.sections[i].questions.length; j++ ) {
                    tmp += this.sections[i].questions[j].id + "\t"
                    tmp += this.sections[i].questions[j].label + "\t"
                    tmp += this.sections[i].questions[j].options[this.sections[i].questions[j].idx][0] + "\t"
                    for (var k = 0; k < this.sections[i].questions[j].levels.length; k++) {
                        tmp += this.sections[i].questions[j].levels[k] + "\t"

                    }

                    if ( this.sections[i].questions[j].options[this.sections[i].questions[j].idx][0] == -1 ) {
                        tmp += this.strings["na"]
                    } else if ( this.sections[i].questions[j].options[this.sections[i].questions[j].idx][0] == 0 && this.sections[i].questions[j].levels[this.targetLevel] == 0) {
                        tmp += this.strings["notConsidered"]
                    } else if ( this.sections[i].questions[j].options[this.sections[i].questions[j].idx][0] == 0 ) {
                        tmp += this.strings["improvement"]
                    } else if ( this.sections[i].questions[j].options[this.sections[i].questions[j].idx][0] > 0 && this.sections[i].questions[j].options[this.sections[i].questions[j].idx][0] < this.sections[i].questions[j].levels[this.targetLevel] ) {
                        tmp += this.strings["furthImprov"]
                    } else if ( this.sections[i].questions[j].options[this.sections[i].questions[j].idx][0] == this.sections[i].questions[j].levels[this.targetLevel] ) {
                        tmp += this.strings["compl"]
                    } else {
                        tmp += this.strings["better"]
                    }

                    tmp += "\n"
                }
            }

            copy(tmp)
            this.$toasted.show("Export to Clipboard successful!");
        },
    }
}
</script>

<style>

.selected {
  background-color: lightgrey;
}

.tableNA {
  background-color: gray;
  text-align: center;
  font-weight: bold;
}

.tableImprovement {
  background-color: red;
  text-align: center;
  font-weight: bold;
}

.tableFurthImprovement {
  background-color: #ffaa00;
  text-align: center;
  font-weight: bold;
}

.tableCompliant {
  background-color: green;
  color: white;
  text-align: center;
  font-weight: bold;
}

.tableBetter {
  background-color: #2CB22C;
  color: white;
  text-align: center;
  font-weight: bold;
}


  .c-alt1 .selected {
    background-color: #d3d3d3;
  }

  .c-alt1 .tableNA {
    background-color: #808080;
  }

  .c-alt1 .tableImprovement {
    background-color: #cc4100;
  }

  .c-alt1 .tableFurthImprovement {
    background-color: #eebf18;
  }

  .c-alt1 .tableCompliant {
    background-color: #195e12;
    color: white;
  }

  .c-alt1 .tableBetter {
    background-color: #5eb455;
    color: black;
  }


  .c-alt2 .selected {
    background-color: #d3d3d3;
  }

  .c-alt2 .tableNA {
    background-color: #808080;
  }

  .c-alt2 .tableImprovement {
    background-color: #D05400;
  }

  .c-alt2 .tableFurthImprovement {
    background-color: #EFC615;
  }

  .c-alt2 .tableCompliant {
    background-color: #175510;
    color: white;
  }

  .c-alt2 .tableBetter {
    background-color: #5CA852;
    color: black;
  }

  .c-alt3 .selected {
    background-color: #d3d3d3;
  }

  .c-alt3 .tableNA {
    background-color: #808080;
  }

  .c-alt3 .tableImprovement {
    background-color: #908E00;
  }

  .c-alt3 .tableFurthImprovement {
    background-color: #DAD929;
  }

  .c-alt3 .tableCompliant {
    background-color: #37381E;
    color: white;
  }

  .c-alt3 .tableBetter {
    background-color: #838565;
    color: black;
  }

  .c-alt4 .selected {
    background-color: #D3D3D3;
  }

  .c-alt4 .tableNA {
    background-color: #808080;
  }

  .c-alt4 .tableImprovement {
    background-color: #9FB200;
  }

  .c-alt4 .tableFurthImprovement {
    background-color: #DFE533;
  }

  .c-alt4 .tableCompliant {
    background-color: #302626;
    color: white;
  }

  .c-alt4 .tableBetter {
    background-color: #7A6E6E;
    color: black;
  }

  .c-alt5 .selected {
    background-color: #D3D3D3;
  }

  .c-alt5 .tableNA {
    background-color: #808080;
  }

  .c-alt5 .tableImprovement {
    background-color: #F20000;
  }

  .c-alt5 .tableFurthImprovement {
    background-color: #FA4950;
  }

  .c-alt5 .tableCompliant {
    background-color: #06373C;
    color: white;
  }

  .c-alt5 .tableBetter {
    background-color: #46838A;
    color: black;
  }

  .c-alt6 .selected {
    background-color: #D3D3D3;
  }

  .c-alt6 .tableNA {
    background-color: 808080;
  }

  .c-alt6 .tableImprovement {
    background-color: #F60000;
  }

  .c-alt6 .tableFurthImprovement {
    background-color: #FC7C1F;
  }

  .c-alt6 .tableCompliant {
    background-color: #045D17;
    color: white;
  }

  .c-alt6 .tableBetter {
    background-color: #44B35C;
    color: black;
  }

  .c-alt7 .selected {
    background-color: #D3D3D2;
  }

  .c-alt7 .tableNA {
    background-color: #80807F;
  }

  .c-alt7 .tableImprovement {
    background-color: #9D2929;
  }

  .c-alt7 .tableFurthImprovement {
    background-color: #D3AD5F;
  }

  .c-alt7 .tableCompliant {
    background-color: #286328;
    color: white;
  }

  .c-alt7 .tableBetter {
    background-color: #71BA71;
    color: black;
  }

</style>
