<template>
  <div class="container mt-4">
      <h3>{{ manual.sub_title }}</h3>
      <div class="version">
        <div v-html="manual.version"></div>
        <div v-html="manual.author"></div>
      </div>

      <h6>{{ manual.abstract_head }}</h6>
      <div v-html="manual.abstract"></div>

      <h6>{{ manual.acknowledgement_head }}</h6>
      <div v-html="manual.acknowledgement"></div>

      <h4>{{ manual.introduction_head }}</h4>
      <div v-html="manual.introduction"></div>

      <h4>{{ manual.quadrants_head }}</h4>
      <div v-html="manual.quadrants"></div>

      <h4>{{ manual.scoring_head }}</h4>
      <div v-html="manual.scoring"></div>

      <div v-for="section in sections" :key="section.key">
          <h3>{{section.key}} - {{section.label}}</h3>
          <div v-html="section.intro_html"></div>

          <div class="mt-4" v-for="question in section.questions" :key="question.id">
              <h6><span class="question-id">{{ question.id }}</span> {{ question.label }}</h6>
              <div class="question-notes" v-html="question.standard"></div>
              <div class="question-description" v-html="question.description"></div>
          </div>
      </div>

      <h5>{{ manual.copyright_head }}</h5>
      <div v-html="manual.copyright"></div>
    </div>
</template>

<script>
export default {
    name: 'manual',
    props: ['sections', 'manual'],
}
</script>

<style scoped>
h5, h6 {
    font-weight: bold;
}

h3, h4, h5, h6 {
    margin-top: 1em;
}

.question-id {
    font-weight: normal;
    margin-right: 1em;
}

.question-notes {
    margin-top: 1em;
    font-style: italic;
}

.question-description {
    margin-top: 1em;
}

.version {
    font-weight: bold;
}
</style>
